<template>
  <div class="addPersonalMain">
    <!-- 选择个人担保弹窗 -->
    <base-dialog
      :visible="visible"
      width="1100px"
      :showFooter="false"
      @close="emitVisible"
      class="addPersonal"
      :title="title"
    >
      <div class="content">

        <!-- 查询条件 -->
        <base-form
          :componentList="addPersonalFrom"
          :formAttrs="{
            model: queryParas,
            labelWidth: '90px',
          }"
          class="formStyle"
          @handleFilter="handleFilter"
          @clearParams="clearParams"
          ref="formData"
        >
          <!-- :btnReset="false" -->
        </base-form>
        <!-- 表格 -->
        <base-table
          :columns="newColumns"
          :showPage="false"
          :tableAttrs="{
            data: tableData,
            stripe: true,
          }"
          @select="select"
          @selection-change="change"
          :dataSource.sync="tableData"
          ref="tableData"
          :isCaculateHeight="false"
        >
          <template slot="index" slot-scope="scope">{{
            scope.$index + 1
          }}</template>
          <template slot="action">
            <!-- 按钮组 -->
            <icon-button
              content="查看"
              icon="iconfont iconchakan"
              @click="visibleDetail = true"
            />
          </template>
        </base-table>
      </div>
      <template slot="footer">
        <base-button label="提 交" @click="confirm"></base-button>
        <base-button
          label="关 闭"
          type="default"
          @click="emitVisible"
        ></base-button>
      </template>
    </base-dialog>
    <!-- 新增用户弹窗 -->

    <!-- <personnel-details :visible.sync="visibleDetail"></personnel-details> -->
  </div>
</template>

<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form.vue'
import { addPersonalFrom, addRelationTableConfig, relationshipTableConfig, tableConfig, newColumns, addTableConfig } from '../utils/make-config-new'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { contactApi, relationApi } from '@/api/contactApi'

import { getDictLists } from '@/filters/fromDict'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
// import PersonnelDetails from './personnel-details.vue'
import Storage from '@/utils/storage'
// import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
// import * as validate from '@/utils/validate'
export default {
  name: 'addPersonal',
  components: {
    baseDialog,
    BaseButton,
    BaseForm,
    BaseTable,

    IconButton

  },
  props: {
    addPersonalType: String,
    hiddenAddUser: Boolean,
    title: {
      type: String,
      default: '选择个人担保信息'
    },
    addTitle: {
      type: String,
      default: '新增个人担保信息'
    },
    visible: Boolean,
    personalData: Array // 外层传入选中的数据
  },
  data () {
    return {
      gysId: Storage.getLocal('admittanceInfo').gysId,
      expandedRows: {},
      total: 0,
      selectData: [], // 已选中的数据
      queryParas: {
        pageSize: 10,
        pageNumber: 1
      },
      loadCount: 0,
      tableData: [],
      addVisible: false,
      changeData: [],
      state: true,
      addData: [{}], // 新增人员数据
      supervise: [
        {
          label: '开户行：',
          prop: 'bankName',
          attrs: {
            placeholder: '请输入',
            maxlength: 50
          },
          rules: [{ required: true, message: '请输入开户行', trigger: 'blur' }]
        },
        {
          label: '账号：',
          prop: 'accountNo',
          attrs: {
            placeholder: '请输入',
            maxlength: 50
          },
          rules: [{ required: true, message: '请输入账号', trigger: 'blur' }]
        }
      ],
      rules: [],
      flag: true, // 控制每次打开弹窗只执行一次带入数据的回显
      visibleDetail: false
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.clearParams()
        // this.tableData = []
        this.flag = true
        this.changeData = []
        this.$set(this.queryParas, 'enterpriseId', this.gysId)
        this.$nextTick(() => {
          this.handleFilter()
        })
      }
    },
    tableData: {
      handler (val) {

      },
      deep: true
    }
  },
  computed: {
    // 新增担保信息子表
    addRelationTableConfig () {
      return addRelationTableConfig()
    },
    relationApi () {
      return relationApi
    },
    // 子表单
    relationshipTableConfig () {
      return relationshipTableConfig(this)
    },
    addPersonalFrom () {
      return addPersonalFrom(this)
    },
    columns () {
      return tableConfig(this)
    },
    newColumns () {
      return newColumns(this)
    },
    addcolumns () {
      return addTableConfig(this)
    },
    api () {
      return contactApi
    },
    getDictLists () {
      return getDictLists
    }
  },
  methods: {
    addRelation (row, i) {
      this.addData[i].personContactInfos.push({ enterpriseId: this.gysId })
    },

    emitVisible () {
      this.$emit('emitVisible', false)
    },
    handleFilter () {
      this.queryParas.pageIndex = 1
      if (this.queryParas.mobile === '') {
        delete this.queryParas.mobile
      }
      this.getlist()
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageNumber: 1,
        enterpriseId: this.gysId
      }
    },
    // 获取表格数据
    getlist () {
      console.log(this.personalData, '传入数据')
      this.expandedRows = {}
      this.queryParas.pageSize = 9999
      const params = { ...this.queryParas, enterpriseId: this.gysId, userType: 20 }

      this.api.getAuthcenterPage(params).then((res) => {
        this.tableData = res.data.datas
        this.tableData.forEach((item, index) => {
          // 每条数据增加personContactInfos展开项数据
          this.$set(this.tableData[index], 'personContactInfos', [])
        })
        if (this.tableData.length > 0) {
          const that = this
          this.$nextTick(() => {
            if (!this.expandedRows) {
              that.$refs.tableData.clearSelection()
            }
            // 回显选中的选项
            for (let i = 0; i < that.tableData.length; i++) {
              for (let j = 0; j < that.personalData.length; j++) {
                // if (that.tableData[i].personnerName === that.personalData[j].personnalName && that.tableData[i].idCard === that.personalData[j].idCard) {
                if (that.tableData[i].userId === that.personalData[j].userId) {
                  that.$refs.tableData.toggleRowSelection(
                    that.tableData[i],
                    true
                  )
                }
              }
            }

            // 显示选中的数据
            for (let i = 0; i < that.tableData.length; i++) {
              for (let j = 0; j < that.changeData.length; j++) {
                if (that.tableData[i].userId === that.changeData[j].userId) {
                  that.$refs.tableData.toggleRowSelection(
                    that.tableData[i],
                    true
                  )
                }
              }
            }
          })
        }
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.total = res.data.totalRow
            this.$refs.tableData.total = res.data.totalRow
          })
        })
      })
    },
    // 父级 改变选项
    select (selection, row) {
      this.changeData.forEach((item, index) => {
        // 取消选中后的数据
        if (item.userId === row.userId) {
          this.changeData.splice(index, 1)
        }
      })
    },
    // 选择数据
    change (data) {
      // this.changeData = []
      if (data.length === 0) {
        const newArr = []
        this.changeData.forEach((item, index) => {
          if (this.tableData.indexOf(item) !== -1) {
            newArr.push(item)
          }
        })
        if (newArr.length > 0) {
          newArr.forEach((item) => {
            if (this.changeData.indexOf(item) !== -1) {
              this.changeData.splice(this.changeData.indexOf(item), 1)
            }
          })
        }
      }
      data.forEach((ele) => {
        let state = true
        this.changeData.forEach((eles) => {
          if (eles.userId === ele.userId) {
            state = false
          }
        })
        if (state) {
          this.changeData.push(ele)
        }
        if (this.changeData.length === 0) {
          this.changeData.push(ele)
        }
        // this.changeData = newArr
        console.log(this.changeData, '初始选中')
      })
    },
    // 提交
    confirm () {
      // 访谈纪要选人逻辑
      console.log(this.changeData, '222')
      this.$emit('choseSuccess', this.changeData)
      this.emitVisible()
    }
  }
}
</script>

<style lang='scss' scoped>
.addRelation {
  display: flex;
  align-items: center;
  > div:nth-child(1) {
    flex: 1;
    text-align: center;
  }
  > div:nth-child(2) {
    cursor: pointer;
    background: #2862E7;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff;
  }
}
.addPersonal /deep/.el-dialog {
  height: 78vh !important;
}
.content {
  padding: 20px;
  .link {
    color: rgb(53, 121, 248);
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
